import { callApiV2 } from "@/lib/config/Api";
import { CartJson } from "@/types/Cart.type";
import { OrderBodyListJson, OrderFilter } from "@/types/Orders.type";

const URL = "/orders/invoice";

async function getOrders(filter: OrderFilter) {
	return callApiV2<OrderBodyListJson>(URL, "get", {
		params: filter,
	});
}

async function getOrder(id: CartJson["id"]) {
	return callApiV2(URL + "/" + id, "get");
}

async function deleteOrderUser(orderId: string | number) {
	return callApiV2(URL + "/orders/cart/order/" + orderId, "put", {
		data: {
			action: "delete",
		},
	});
}

export { getOrders, getOrder, deleteOrderUser };
