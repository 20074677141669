import {
	CashflowreceiptJson,
	CashflowreceiptsCreateInput,
	CashflowreceiptsMethod,
	CashflowreceiptsStatus,
} from "@/types/Cashflowreceipts.type";
import { CouponJson } from "@/types/Coupon.type";
import {
	PromotionDiscountType,
	PromotionGroupType,
	PromotionJson,
} from "@/types/Promotion.type";
import { PricePos, useOrderProps } from "@/types/UseOrder.type";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createDeliveries, getDeliveries } from "../api/shop/delivery";
import { DeliveryInternalAddEditJson } from "@/types/Delivery.type";
import {
	createCashflowreceipts,
	createCashflowreceiptsPublic,
	updateCashflowreceipts,
} from "../api/shop/cashflowreceipts";
import { DeliveryJson } from "@/types/Delivery.type";
import { useOrderError, SumaryOrder } from "@/types/UseOrder.type";
import { getDataError } from "../config/apiHandler";
import {
	createOrderProps,
	OrderJson,
	updateCartItemQuantityProps,
} from "@/types/Orders.type";
import {
	CartAddEditJson,
	CartOrderJson,
	CartPromotion,
	IsUse,
	ProductCartAddEditJson,
} from "@/types/Cart.type";
import useBrowerId from "./useBrowerId";
import OrderRepo from "../repo/order";
import {
	createCartPublic,
	createCartUser,
	createOrderPublic,
	createOrderUser,
	deletePublicCart,
	deleteUserCart,
	postActionCartPublic,
	postActionCartUser,
} from "../api/shop/cart";
import { checkCoupon } from "../api/shop/coupon";
import { mapCouponUpdate } from "@/utils/coupon";
import { ProductJson } from "@/types/Product.type";
import { PaymentDataSubmit } from "@/types/Payment.type";
import { checkoutAction } from "@/actions/payment";
import CheckoutRepo from "../repo/checkout";
import { mergeObjects } from "@/utils/utils";
import Helper from "@/utils/helper";
import { useSession } from "next-auth/react";
import useStoreId from "./useStoreId";
import { createTransactionVNpay, quickRegister } from "../api/shop/payment";
import { ORDER_STATUS } from "@/common/constants/order";
import BaseApi from "@/common/api/BaseApi";
import useCashFlows from "./useCashFlows";
import useDeliveries from "./useDeliveries";
import Cart from "@/common/constants/cart";
import { getSettingV2 } from "../api/shop/setting";
import SettingApi from "@/common/constants/setting";
import { deleteOrderUser } from "../api/shop/orders";
export type useOrderReturn = ReturnType<typeof useOrder>;

const useOrder = ({
	order: orderDefault,
	isUser: isUserDefault,
	defaultParams,
	storeId: storeIdDefault,
	skipInit = false,
	onUpdateOrder,
}: useOrderProps) => {
	const defaultError: useOrderError = {
		action: [],
		cashFlows: [],
		checkout: [],
		deliveries: [],
		init: [],
		order: [],
	};
	///////////////////////////////////
	const [order, setOrder] = useState<OrderJson>(orderDefault);
	// const [cashFlows, setCashFlows] = useState<CashflowreceiptJson[]>([]);
	const [promotions, setPromotions] = useState<CartPromotion[]>(
		orderDefault?.promotions || []
	);
	const [coupons, setCoupons] = useState<CouponJson[]>([]);
	// const [deliveries, setDeliveries] = useState<DeliveryJson[]>([]);

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<useOrderError>(defaultError);

	const [extraCharges, setExtraCharges] = useState({
		priceShipping: order.price_shipping,
	});

	const [sumaryOrderData, setSumaryOrderData] = useState<SumaryOrder>({
		priceSell: 0, // Tổng tiền đơn hàng
		priceDiscountTotalProducts: 0,
		priceDiscountTotalItemsAndBody: 0,
		priceDiscountVoucher: 0,
		priceDiscountTotalOrder: 0,
		priceSaveTotal: 0, // Tổng tiết kiệm
		priceFinal: 0, // Tổng tiền thanh toán
		order_custom_discount: 0, // Tiền giảm custom POS
		priceDiscountPromotionTotal: 0, // Tổng tiền giảm khuyễn mãi (item + body)
	});

	const [pricePosData, setPricePosData] = useState<PricePos>({
		totalOrder: 0,
		totalCashFlows: 0,
		totalAmountPaid: 0,
		totalAmountPayable: 0,
		priceVat: 0,
		totalReturn: 0,
	});

	///////////////////////////////////
	const { data: browserId } = useBrowerId();
	const { data: cus, status } = useSession();
	const user = cus?.user.company;

	let isUser = !!isUserDefault;
	if (user && user.employee && defaultParams?.type_sale === "employee") {
		isUser = true;
	}

	if (user && !user.employee && defaultParams?.type_sale !== "employee") {
		isUser = true;
	}

	const { data: store_id, isLoading: isLoadingStoreId } =
		useStoreId(storeIdDefault);
	const {
		cashFlows,
		setCashFlows,
		loadingCashFlows,
		error: errorCashFLows,
	} = useCashFlows({
		orderId: order.order_id,
		skip: skipInit,
	});

	const { deliveries, setDeliveries, loadingDeliveries } = useDeliveries({
		orderId: order.id,
		skip: skipInit,
	});

	const browser_id = browserId;
	const customer_token = order.customer_token;

	const defaultCouponItems = order.details.data
		.filter((item) => item.is_use === IsUse.USE)
		.flatMap((data) => data.promotions)
		.filter((pro) => pro.promotion_detail.group === PromotionGroupType.coupon);

	///////////////////////////////////
	// common + private
	async function handleAction(body: CartAddEditJson) {
		try {
			const dataBody: CartAddEditJson = {
				...body,
				customer_id: order.customer_id ?? body.customer_id,
			};
			const { data } = isUser
				? await postActionCartUser(dataBody)
				: await postActionCartPublic(dataBody);
			onFinish({ ...data, customer_id: order.customer_id ?? body.customer_id });

			return data;
		} catch (error) {
			throw getDataError(error);
		}
	}
	const updateProductOder = useCallback(
		async (datas: ProductCartAddEditJson[]) => {
			const orderId = order.id;
			const submitData: CartAddEditJson = {
				cart_id: orderId,
				action: "update",
				details: datas,
				// browser_id: browser_id,
				customer_token: customer_token,

				...defaultParams,
			};
			const responsePostCart = await handleAction(submitData);
			return responsePostCart;
		},
		[order, handleAction]
	);
	const onFinish = (o: OrderJson) => {
		setOrder(o);
		onUpdateOrder(o);
	};

	///////////////////////////////////
	// action
	async function updateCartItemQuantity({
		item_id,
		quantity,
	}: updateCartItemQuantityProps) {
		const item = order.details.data.find((item) => item.id === item_id);
		if (!item) return;
		const postData: CartAddEditJson = {
			cart_id: order.id,
			action: "update",
			details: [OrderRepo.convertDetailsForUpdateQuantityItem(item, quantity)],
			customer_token: customer_token,
			...defaultParams,
		};
		return handleAction(postData);
	}

	async function addPromotionBody(promotions: PromotionJson[]) {
		const dataUpdate = OrderRepo.convertPromotionToOrderPromotion(
			promotions,
			IsUse.USE,
			1
		);

		const submitData: CartAddEditJson = {
			action: "promotion",
			cart_id: order.id,
			// details: order.details.data,
			promotions: dataUpdate,
			...defaultParams,
		};

		return handleAction(submitData);
	}

	async function addCouponOrder(code: string) {
		const updateCouponItem = (detailData: CartOrderJson[]) => {
			const dataUpdate = detailData.map(({ product_json, ...res }) => {
				return {
					...res,
				};
			});
			const data: CartAddEditJson = {
				action: "update",
				cart_id: order.id,
				customer_token,
				details: dataUpdate,
				...defaultParams,
			};

			return handleAction(data);
		};

		const updateCouponBody = (coupons: CartPromotion[]) => {
			const data: CartAddEditJson = {
				action: "promotion",
				cart_id: order.id,
				promotions: coupons,
				...defaultParams,
			};

			return handleAction(data);
		};

		try {
			const products = order.details.data;
			const promotionsBody = order.promotions;
			const { data: couponCheckJson } = await checkCoupon({
				code: code,
				order_id: order.id,
			});
			const { couponBodyUpdate, productsCouponUpdate } = mapCouponUpdate(
				couponCheckJson,
				products,
				promotionsBody
			);
			const promises = [];

			if (couponBodyUpdate.length) {
				promises.push(
					updateCouponBody(
						Helper.removeDuplicatesArrObject(
							[...promotions, ...couponBodyUpdate],
							"id"
						)
					)
				);
			}

			if (productsCouponUpdate.length) {
				promises.push(updateCouponItem(productsCouponUpdate));
			}

			if (promises.length > 0) {
				const result = await Promise.all(promises);
				return result;
			} else {
				throw new Error("data_add_coupon_empty");
			}
		} catch (error) {
			// const dataError = getDataError(error);
			throw error;
		}
	}

	const removeCouponOrder = useCallback(
		async (coupon: CartPromotion) => {
			const typeCoupn = coupon.promotion_detail.discount_type;
			const codeRemove = coupon.code;

			if (typeCoupn === PromotionDiscountType.PRODUCT) {
				const listCartItemContainsCoupon = order.details.data.filter((item) =>
					item.promotions.some((p) => p?.code === coupon?.code)
				);
				const listCartItemUpdate: ProductCartAddEditJson[] =
					listCartItemContainsCoupon.map((item) => {
						return {
							...item,
							promotions: item.promotions.filter(
								(pro) => pro.code !== codeRemove
							),
						};
					});
				return await updateProductOder(listCartItemUpdate);
			}

			if (typeCoupn === PromotionDiscountType.CART) {
				const coupons = order.promotions.filter(
					(pro) => pro.code !== codeRemove
				);
				const data: CartAddEditJson = {
					action: "update",
					cart_id: order.id,
					details: coupons,
					...defaultParams,
				};
				return await handleAction(data);
			}
		},
		[JSON.stringify(order)]
	);

	const updatePriceTax = useCallback(
		async (value: number) => {
			setLoading(true);
			try {
				const data: CartAddEditJson = {
					action: "update_price",
					cart_id: order.id,
					price_tax: value,
					type_sale: "employee",
				};
				const res = await handleAction(data);
				onFinish(res);
				return res;
			} catch (error) {
				throw getDataError(error);
			} finally {
				setLoading(false);
			}
		},
		[order]
	);

	const updateVatInNote = useCallback(
		async (dataVat: PaymentDataSubmit["note_invoice"]) => {
			setLoading(true);
			try {
				const data: CartAddEditJson = {
					action: "note",
					cart_id: order.id,
					type_sale: "employee",
					note_invoice: dataVat,
					// details: OrderRepo.convertDetailCart(order.details.data),
				};
				const res = await handleAction(data);
				onFinish(res);
				return res;
			} catch (error) {
				throw getDataError(error);
			} finally {
				setLoading(false);
			}
		},
		[order]
	);

	const updatePriceDiscount = useCallback(
		async (value: number) => {
			setLoading(true);

			try {
				const data: CartAddEditJson = {
					action: "update_discount",
					cart_id: order.id,
					discount_custom_type: "amount",
					discount_custom_value: value,
					type_sale: "employee",
				};
				const res = await handleAction(data);
				onFinish(res);
				return res;
			} catch (error) {
				throw error;
			} finally {
				setLoading(false);
			}
		},
		[order.id]
	);

	//////////// action on productOrder

	const buyNow = useCallback(
		async (product: ProductJson, quantity: number) => {
			const listItem = order.details.data;
			const isExitedInCart = listItem.some((i) => i.product_id === product.id);
			const jsonForAddInCart = OrderRepo.convertProductToProductOrder(
				product.id,
				quantity,
				OrderRepo.convertPromotionToOrderPromotion(
					product.promotions,
					quantity,
					IsUse.USE
				)
			);
			if (listItem.length > 0) {
				const listItemUpdate: CartOrderJson[] = listItem.map((i) => {
					if (i.product_id === product.id) {
						return {
							...i,

							is_use: IsUse.USE,
							item_quantity: quantity,
						};
					}
					return {
						...i,
						is_use: IsUse.NOT_USE,

						item_quantity: quantity,
					};
				});

				try {
					const resUpdateIsUse = await updateManyIsUseProductOrder(
						listItemUpdate
					);
					if (!isExitedInCart) {
						return await addManyProductOder([jsonForAddInCart]);
					}
					return resUpdateIsUse;
				} catch (error) {
					throw getDataError(error);
				}
			} else {
				return await addManyProductOder([jsonForAddInCart]);
			}
		},
		[order]
	);

	const addManyProductOder = useCallback(
		async (datas: ProductCartAddEditJson[], id?: string) => {
			try {
				//case not default order or cart
				let orderId = id || order.id;
				let customerTokenData = order.customer_token;
				if (orderId.toString().length <= 0) {
					try {
						const res = await initPreOrder();
						orderId = res.id;
						customerTokenData = res.customer_token;
					} catch (error) {}
				}

				const submitData: CartAddEditJson = {
					cart_id: orderId,
					action: "add",
					details: datas,
					customer_token: customerTokenData,
					...defaultParams,
				};
				const responsePostCart = await handleAction(submitData);
				onFinish(responsePostCart);
				return responsePostCart;
			} catch (error) {
				const err = getDataError(error);
				throw err;
			}
		},
		[order.id, handleAction]
	);

	const updateVariantProductOrder = useCallback(
		async (productOrder: CartOrderJson, productVariantNew: ProductJson) => {
			try {
				const item: ProductCartAddEditJson = {
					id: productOrder.id,
					product_id: productVariantNew.id,
					item_quantity: productOrder.item_quantity,
					promotions: OrderRepo.convertPromotionToOrderPromotion(
						productVariantNew.promotions
					),
					is_use: productOrder.is_use,
				};
				const data = await updateProductOder([item]);
				return data;
			} catch (error) {
				const err = getDataError(error);
				throw err;
			}
		},
		[updateProductOder]
	);

	const updatePromotionProductOrder = useCallback(
		async (productOrder: CartOrderJson, promotionsNew: CartPromotion[]) => {
			try {
				const item: ProductCartAddEditJson = {
					id: productOrder.id,
					item_quantity: productOrder.item_quantity,
					product_id: productOrder.product_id,
					promotions: promotionsNew,
				};
				const data = await updateProductOder([item]);
				return data;
			} catch (error) {
				const err = getDataError(error);
				throw err;
			}
		},
		[updateProductOder]
	);

	const updateManyIsUseProductOrder = useCallback(
		async (productsOrderUpdate: CartOrderJson[], isUse?: IsUse) => {
			const details: ProductCartAddEditJson[] = productsOrderUpdate.map(
				(productOrder) => ({
					id: productOrder.id,
					is_use: isUse ?? productOrder.is_use,
					product_id: productOrder.product_id,
					item_quantity: productOrder.item_quantity,
					promotions: productOrder.promotions,
				})
			);
			try {
				const data = await updateProductOder(details);
				return data;
			} catch (error) {
				const err = getDataError(error);
				throw err;
			}
		},
		[updateProductOder]
	);

	const deleteManyProductOrder = useCallback(
		async (productsOrderDelete: CartOrderJson[]) => {
			try {
				const submitData: CartAddEditJson = {
					action: "delete",
					cart_id: order.id,
					details: productsOrderDelete.map((item) => ({ id: item.id })),
					customer_token: customer_token,
					...defaultParams,
				};
				const data = await handleAction(submitData);
				return data;
			} catch (error) {
				const err = getDataError(error);
				throw err;
			}
		},
		[order.id, handleAction]
	);

	const updateCustomer = useCallback(
		async (customer_id: number) => {
			const orderId = order.id;
			const submitData: CartAddEditJson = {
				cart_id: orderId,
				action: "update_customer",
				customer_token: customer_token,
				customer_id,
				...defaultParams,
			};
			const responsePostCart = await handleAction(submitData);
			return responsePostCart;
		},
		[order, handleAction]
	);

	///////////////////////////////////
	// checkout
	const checkout = useCallback(
		async (data: Omit<PaymentDataSubmit, "order_id">, type: "pos" | "shop") => {
			setLoading(true);
			try {
				const resCheckout =
					type === "shop" ? await checkoutShop(data) : await checkoutPos(data);
				return resCheckout;
			} catch (error) {
				throw getDataError(error);
			} finally {
				setLoading(false);
			}
		},
		[order]
	);
	async function handleDeleteCart(cart_id: string, customer_token?: string) {
		const res = isUser
			? deleteUserCart({ cart_id })
			: deletePublicCart({ cart_id, customer_token });
		return res;
	}
	////////////// checkout with type
	const checkoutShop = useCallback(
		async (data: Omit<PaymentDataSubmit, "order_id">) => {
			try {
				if (!isUser) {
					const dataCusNew =
						OrderRepo.convertPaymentDataToQuickRegisterCustomer(data);
					const resQuickRegister = await quickRegister(dataCusNew);
					data.customer_id = resQuickRegister.data.customer_id;
				}

				// create order
				const resCreateOrder = await createOrder({
					cart_id: order.id,
					store_id: store_id || 0,
					customer_token: customer_token,
					customer_id: data.customer_id,
					...OrderRepo.convertDataShippingForCreateOrder(data),
				});

				// create cashFlows
				const methodPayment = data.paymentMethod;

				if (!methodPayment) {
					throw Error("payment_method_empty");
				}
				const cashflow = CheckoutRepo.convertCashFlowsFromPaymentMethod([
					{ amount: order.debt, method: methodPayment },
				]);

				const cashflowRes = await createCashflowreceiptsPublic({
					status: CashflowreceiptsStatus.DRAFT,
					// name: order.code,
					// identifier: order.code,
					target: 3,
					source: 1,
					source_id: resCreateOrder.order_id,
					target_id: data.customer_id,
					store_id: store_id,
					...cashflow[0],
				});
				const { data: saleChannel } = await getSettingV2(
					SettingApi.KEY.id_ecomplatforms_for_web
				);
				// checkout order
				const { data: resCheckout } = await checkoutAction(
					{
						...data,
						order_id: resCreateOrder.id,
						customer_token: customer_token,
						sale_channel: saleChannel.value,
					},
					isUser
				);

				let urlPayment = "";
				if (data.paymentMethod === "vnpay" && cashflowRes) {
					const { data: res } = await createTransactionVNpay({
						order_id: resCheckout.id,
						return_url: `${location.origin}/checkouts/vnpay`,
						cashflow_receipt_id: cashflowRes.id || 0,
					});
					urlPayment = res.payment_url;
				}
				await handleDeleteCart(order.id);
				return { ...resCheckout, url_payment: urlPayment || undefined };
			} catch (error) {
				console.log("🚀 ~ error:", error);
				throw getDataError(error);
			}
		},
		[order]
	);

	const checkoutPos = useCallback(
		async (data: Omit<PaymentDataSubmit, "order_id">) => {
			try {
				if (cashFlows.length <= 0) {
					throw BaseApi.handleErrorCore({
						errors: ["no_data_cashflows"],
						status: 406,
					});
				}

				const dataCashFlowToUpdate = cashFlows
					.filter(
						(i) =>
							i.status === CashflowreceiptsStatus.DRAFT &&
							i.method === CashflowreceiptsMethod.CASH
					)
					.map((ii) => ({
						id: ii.id,
						data: { status: CashflowreceiptsStatus.SUCCSECC },
					}));
				if (dataCashFlowToUpdate.length > 0) {
					await updateCashFlows(dataCashFlowToUpdate, false);
				}

				// try {
				// 	const results = await Promise.allSettled([
				// 		resUpdateCashFlows,
				// 		deliveryPromise,
				// 	]);

				// 	const cashFlowResult = results[0];
				// 	console.log("🚀 ~ cashFlowResult:", cashFlowResult);
				// 	if (cashFlowResult.status === "rejected") {
				// 		throw cashFlowResult.reason;
				// 	}
				// } catch (error) {}

				/////////////////////////
				const { data: saleChannel } = await getSettingV2(
					SettingApi.KEY.id_ecomplatforms_for_pos
				);

				const { data: resCheckout } = await checkoutAction(
					{ ...data, order_id: order.id, sale_channel: saleChannel.value },
					isUser
				);
				// checkout - create delivery + craete cashFlows
				if (!resCheckout?.invoice_id) {
					throw BaseApi.handleErrorCore({
						errors: ["respons_checkout_not_invoiceid"],
						status: 406,
					});
				}

				try {
					const deliveryPromise = createDelivery({
						cod: order.total_payment,
						contact_address: data.shipping_full_address || "",
						contact_fullname: data.shipping_full_name || "",
						contact_phone: data.shipping_phone || "",
						cost_total: order.total_payment,
						cotips: 0,
						note: "",
						partner_delivery: 0,
						resource_id: resCheckout.invoice_id,
						resource_type: 1,
					});
				} catch (error) {}

				return resCheckout;
			} catch (error) {
				throw getDataError(error);
			}
		},
		[order]
	);

	const createOrder = useCallback(async (data: createOrderProps) => {
		try {
			const res = isUser
				? await createOrderUser(data)
				: await createOrderPublic(data);
			// if (res.data) {
			// 	onFinish(res.data);
			// }
			return res.data as OrderJson;
		} catch (error) {
			throw getDataError(error);
		}
	}, []);

	const deleteOrder = useCallback(async (orderId: string | number) => {
		const res = await deleteOrderUser(orderId);
		return res;
	}, []);

	const createPreOrder = useCallback(async () => {
		try {
			const res = isUser
				? await createCartUser({
						store_id: store_id,
				  })
				: await createCartPublic({
						store_id: store_id,
						customer_token: browser_id,
				  });
			if (res.data) {
				onFinish(res.data);
			}
			return res.data;
		} catch (error) {
			throw getDataError(error);
		}
	}, []);

	const initPreOrder = useCallback(async () => {
		const hasOrder = order.id.toString().length > 0;

		if (!hasOrder) return createPreOrder();

		return order;
	}, []);

	const createCashFlows = useCallback(
		async (data: Partial<CashflowreceiptJson>[]) => {
			// const defaultData = {
			// 	status: 1, //1: nháp | 3: chờ duyệt | 5: duyệt | 9: hoàn thành | 11: cancel
			// 		direction: 5, //5 là dạng phiếu thu | 10 là dạng phiếu chi
			// 		source_id: order.id, //id tham chiếu là đơn hàng
			// 		source: 1, //type 1 là đơn hàng
			// 		target: 3, // 3 là khách hàng
			// 		target_id: order.customer_id, //id khách hàng
			// 		creator_id: cus?.user?.company?.employee?.id || 0, // người tạo
			// 		identifier: order.code, //order code
			// 		name: order.code, //order code
			// 		note: "", //ghi chú
			// 		cashflow_group: 101, //group bán hàng
			// }
			// const mergeData = mergeObjects(defaultData,data)
			setLoading(true);
			try {
				const promises = data.map((d) =>
					isUser
						? createCashflowreceipts({
								status: CashflowreceiptsStatus.DRAFT,
								// name: order.code,
								// identifier: order.code,
								target: 3,
								target_id: order.customer_id,
								source: 1,
								source_id: order.order_id,
								...d,
						  })
						: createCashflowreceiptsPublic({
								status: CashflowreceiptsStatus.DRAFT,
								// name: order.code,
								// identifier: order.code,
								target: 3,
								source: 1,
								source_id: order.order_id,
								target_id: order.customer_id,
								...d,
						  })
				);

				const res = await Promise.all(promises);
				if (res) {
					const dataPush = [...res] as CashflowreceiptJson[];
					setCashFlows((prev: CashflowreceiptJson[]) => [...prev, ...dataPush]);
				}

				return res;
			} catch (error) {
				throw getDataError(error);
			} finally {
				setLoading(false);
			}
		},
		[]
	);

	const updateCashFlows = useCallback(
		async (
			data: { id: number; data: Partial<CashflowreceiptJson> }[],
			isProcessLoading?: boolean
		) => {
			isProcessLoading && setLoading(true);

			try {
				const hasNotValidValue = data.some(
					(item) => item.data.hasOwnProperty("value") && item.data.value == 0
				);
				if (hasNotValidValue) {
					throw BaseApi.handleErrorCore({
						errors: ["value_not_in_valid"],
						status: 406,
					});
				}
				const dataMap = data.reduce(
					(curr: CashflowreceiptJson[], cashUpdate) => {
						const cashFlowExited = cashFlows.find(
							(i) => i.id === cashUpdate.id
						);
						if (cashFlowExited) {
							const dataMerge = mergeObjects(
								cashFlowExited,
								cashUpdate.data
							) as CashflowreceiptJson;
							curr.push(dataMerge);
						}
						return curr;
					},
					[]
				);
				if (!dataMap.length) {
					throw BaseApi.handleErrorCore({
						errors: ["no_date_update"],
						status: 406,
					});
				}

				const promises: Promise<CashflowreceiptJson>[] = dataMap.map((item) => {
					const dataUpdate = Helper.convertParams(
						item
					) as Partial<CashflowreceiptJson>;
					return updateCashflowreceipts(dataUpdate);
				});

				const res = await Promise.all(promises);

				const dataNewUpdate = cashFlows.reduce(
					(curr: CashflowreceiptJson[], prev: CashflowreceiptJson) => {
						const cashflowinUpdated = res.find((i) => i.id === prev.id);
						if (cashflowinUpdated) {
							const dataMerge = mergeObjects(
								prev,
								cashflowinUpdated
							) as CashflowreceiptJson;

							curr.push(dataMerge);
						} else {
							curr.push(prev);
						}

						return curr;
					},
					[]
				);

				setCashFlows(
					dataNewUpdate.filter(
						(i) => i.status !== CashflowreceiptsStatus.CANCEL
					)
				);

				return res;
			} catch (error) {
				throw getDataError(error);
			} finally {
				isProcessLoading && setLoading(false);
			}
		},
		[order, cashFlows]
	);

	const createDelivery = useCallback(
		async (data: DeliveryInternalAddEditJson) => {
			try {
				const res = await createDeliveries(data);
				return res;
			} catch (error) {
				throw getDataError(error);
			}
		},
		[]
	);

	///////////////////////////////////
	// calc + const
	const calcPriceItem = useCallback(
		(item: CartOrderJson, q?: number) => {
			return OrderRepo.calcPriceOrderItem(item, q);
		},
		[order]
	);

	const productOrders = order.details.data.filter(
		(data) => data.is_use === IsUse.USE
	);

	const couponItems = productOrders
		.flatMap((data) => data.promotions)
		.filter((pro) => pro.promotion_detail.group === PromotionGroupType.coupon);
	const vouchers = order.payments.filter((item) => item.method === "voucher");

	const sumaryOrder = useMemo(() => {
		// total price origin of product
		const priceSell = productOrders.reduce((pre, pro) => {
			if (pro.product_json.compare_at_price) {
				return pre + pro.product_json.compare_at_price * pro.item_quantity;
			}
			return pre + pro.item_unit_price * pro.item_quantity;
		}, 0);

		// total discount price  of product
		const priceDiscountTotalProducts = productOrders.reduce((pre, pro) => {
			if (
				pro.promotions.find(
					(promo) =>
						promo.is_use === IsUse.USE &&
						promo.promotion_detail.group === PromotionGroupType.seasonal
				)
			) {
				return (
					pre +
					pro.price_discount +
					pro.product_json.compare_discount * pro.item_quantity
				);
			}
			return pre + pro.product_json.compare_discount * pro.item_quantity;
		}, 0);

		const priceDiscountCouponOnItem = couponItems.reduce((pre, pro) => {
			return pre + (pro.discount || 0);
		}, 0);

		const priceDiscountTotalItemsAndBody =
			order.item_discount + order.order_discount;

		const priceDiscountVoucher = vouchers.reduce((pre, item) => {
			return pre + item.amount;
		}, 0);

		const priceDiscountTotalOrder =
			priceDiscountTotalItemsAndBody + priceDiscountVoucher;

		const priceVat = Math.floor((order.price_tax / 100) * order.price_sell);

		const order_custom_discount = order.order_custom_discount;
		const priceFinal =
			order.price_final +
			// priceVat +
			order.price_shipping -
			order_custom_discount;

		const priceDiscountPromotionTotal =
			order.order_discount + priceDiscountCouponOnItem;

		const priceSaveTotal =
			priceDiscountPromotionTotal + priceDiscountTotalProducts;

		//////////////////////////
		// Tổng tiết kiệm

		return {
			priceSell,
			priceDiscountTotalProducts,
			priceDiscountTotalItemsAndBody,
			priceDiscountVoucher,
			priceDiscountTotalOrder,
			priceSaveTotal,
			priceFinal,
			order_custom_discount,
			priceDiscountPromotionTotal,
		};
	}, [order]);

	const pricePos = useMemo(() => {
		const priceShipping = order.price_shipping;
		const totalOrder = order.total_payment + priceShipping;
		const totalCashFlows = cashFlows.reduce((curr, prev) => {
			curr += prev.value;

			return curr;
		}, 0);

		//

		const totalAmountPaid = totalCashFlows;
		const totalAmountPayable = totalOrder - totalAmountPaid;
		const priceVat = Math.floor((order.price_tax / 100) * order.price_sell);

		const priceReturn = totalAmountPaid - totalOrder;
		const totalReturn = priceReturn > 0 ? priceReturn : 0;
		return {
			totalOrder,
			totalCashFlows,
			totalAmountPaid,
			totalAmountPayable,
			priceVat,
			totalReturn,
		};
	}, [order, cashFlows]);

	const isHasVat = useMemo(() => {
		let result = false;
		if (
			typeof order.note_invoice === "object" &&
			order.note_invoice.hasOwnProperty("bill_recipient_name")
		) {
			result = true;
		}

		return result;
	}, [order.note_invoice]);

	///////////////////////////////////
	// ueffect

	useEffect(() => {
		// if (hadOrder && order.status === 12) {
		setOrder(orderDefault);
		setPromotions(orderDefault.promotions);
		// }
	}, [JSON.stringify(orderDefault)]);

	useEffect(() => {
		setSumaryOrderData(sumaryOrder);
	}, [sumaryOrder]);

	useEffect(() => {
		setPricePosData(pricePos);
	}, [pricePos]);

	useEffect(() => {
		return () => {
			setCashFlows([]);
		};
	}, []);

	return {
		order,
		cashFlows,
		promotions,
		coupons,
		deliveries,
		loading: loading || loadingCashFlows,
		error,
		pricePosData,
		sumaryOrderData,
		defaultCouponItems,
		isHasVat,
		setOrder,
		setCashFlows,
		addCouponOrder,
		updateCartItemQuantity,
		addPromotionBody,
		addManyProductOder,
		updateVariantProductOrder,
		updatePromotionProductOrder,
		updateManyIsUseProductOrder,
		updatePriceTax,
		updateVatInNote,
		deleteManyProductOrder,
		removeCouponOrder,
		createOrder,
		createCashFlows,
		updateCashFlows,
		createDelivery,
		checkout,
		buyNow,
		calcPriceItem,
		updateCustomer,
		deleteOrder,
		updatePriceDiscount,
	};
};

export default useOrder;
